import axios from 'axios'

export const generatePassword = async (login) => {
  const request = await axios.get('/proxy.php', {
    params: {
      className: 'login',
      action: 'send_password',
      login,
    },
  })

  return request
}

export const login = async ({ login, password }) => {
  const request = await axios.get('/proxy.php', {
    params: {
      className: 'login',
      login,
      pass: password,
      checkbox: 1,
    },
  })

  const sessionId = await request.data.data.session_id

  localStorage.setItem('sessionId', sessionId)

  return request
}
